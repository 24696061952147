// Customizable Area Start
import React from "react";
import { Grid, Box, Button, CircularProgress } from "@material-ui/core";
import moment from "moment";
import { AvatarGroup } from "@material-ui/lab";
import Loader from "../../../components/src/Loader.web";
import Avatar from "@material-ui/core/Avatar";
import { BackArrow, Play, NoThumbnail } from "./assets";
import InfiniteScroll from "react-infinite-scroll-component";
import { styled } from "@material-ui/core/styles";
import FilteritemsNewController, {
  Props
} from "./FilterItemsNewController.web";

// Customizable Area End
export default class FilteritemsNew extends FilteritemsNewController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        {this.state.loading && <Loader loading={this.state.loading} />}
        <Wrapper>
          <InfiniteScroll
            className="scoller-infinite"
            dataLength={this.state.renderFilter.length}
            next={this.filterItemFunction}
            hasMore={this.state.newPostListCount > 0}
            loader={
              <Box display={"flex"} justifyContent={"center"}>
                {this.state.pageLoder && <CircularProgress />}
              </Box>
            }
          >
            <Box
              component={"section"}
              bgcolor={"#fff"}
              borderRadius={"12px"}
              padding={"24px"}
              border={"1px solid #808080"}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                gridGap={"24px"}
                mb={"24px"}
              >
                <img
                  src={BackArrow}
                  className="pointer"
                  alt="back"
                  onClick={this.handleBack}
                />
                <Box
                  component={"h2"}
                  m={0}
                  fontFamily={"Verdana"}
                  fontSize={"20px"}
                  fontWeight={"bold"}
                  color="#005487"
                  className="title"
                >
                  {this.serchedItem}{" "}
                </Box>
              </Box>
              {this.state.renderFilter.length === 0 && (
                <Box
                  component={"p"}
                  fontFamily={"Verdana"}
                  color={"#808080"}
                >{`No ${this.serchedItem} found.`}</Box>
              )}
              {this.serchedItem === "photos" && (
                <Box className="photoContainer">
                  {this.state.renderFilter.map(item => (
                    <Box
                      height={"195px"}
                      width={"195px"}
                      borderRadius={"12px"}
                      key={item.id}
                      position={"relative"}
                      className="pointer"
                      onClick={() => this.navigatePosts(item.id)}
                      data-test-id="list-item"
                    >
                      <img
                        src={item.attributes.images_and_videos[0]}
                        alt="image"
                        className="photoImg"
                      />
                      <Box
                        component={"p"}
                        m={0}
                        fontFamily={"Verdana"}
                        fontSize={"17px"}
                        className="ellipsis photoAuthor"
                        fontWeight={"bold"}
                        color="#fff"
                      >
                        {item.attributes.full_name
                          ? item.attributes.full_name
                          : item.attributes.community_name}{" "}
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}
              {this.serchedItem === "videos" && (
                <Box className="videoContainer">
                  {this.state.renderFilter.map(item => (
                    <Box key={item.id}>
                      <Box
                        component="p"
                        fontFamily={"verdana"}
                        fontSize={"17px"}
                        color="#808080"
                        m={0}
                        mb={"15px"}
                      >
                        <Box component={"span"} fontWeight={"bold"}>
                          {this.renderHeader(
                            item.attributes.full_name,
                            item.attributes.community_name
                          )}
                        </Box>{" "}
                        {this.sharingTime(item.attributes.sharing_time)}
                      </Box>
                      <Box
                        pb={"20px"}
                        borderBottom={"1px solid #808080"}
                        className="pointer"
                        onClick={() => this.navigatePosts(item.id)}
                        data-test-id="list-item"
                      >
                        {this.renderDescription(
                          item.id,
                          item.attributes.description
                        )}
                        <Box display={"flex"}>
                          {item.attributes.video_thumbnail && (
                            <Box
                              position={"relative"}
                              sx={{ width: "200px", height: "133px" }}
                              flexShrink={0}
                            >
                              <Box
                                component="span"
                                position={"absolute"}
                                className={"playIcon"}
                              >
                                <img
                                  src={Play}
                                  alt="play"
                                  width="100%"
                                  height="100%"
                                />
                              </Box>
                              <img
                                src={item.attributes.video_thumbnail}
                                alt="post"
                                width={"100%"}
                                height={"100%"}
                              />
                            </Box>
                          )}
                          <Box
                            color="#808080"
                            ml={"16px"}
                            flexGrow={1}
                            overflow={"hidden"}
                          >
                            {item.attributes.video_title && (
                              <Box
                                component={"p"}
                                m={0}
                                mb={"16px"}
                                fontFamily={"Verdana"}
                                fontSize={"20px"}
                                fontWeight={"bold"}
                                className="ellipsis"
                              >
                                {item.attributes.video_title}
                              </Box>
                            )}
                            <Box
                              component="span"
                              fontFamily={"Verdana"}
                              fontSize={"14px"}
                            >
                              {this.returnVideoName(item.attributes.post_type)}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}
              {this.serchedItem === "urls" && (
                <Box className="urlContainer">
                  {this.state.renderFilter.map(item => (
                    <Box key={item.id}>
                      <Box
                        component="p"
                        fontSize={"17px"}
                        fontFamily={"verdana"}
                        m={0}
                        color="#808080"
                        mb={"15px"}
                      >
                        <Box fontWeight={"bold"} component={"span"}>
                          {this.renderHeader(
                            item.attributes.full_name,
                            item.attributes.community_name
                          )}
                        </Box>{" "}
                        {this.sharingTime(item.attributes.sharing_time)}
                      </Box>
                      <Box
                        pb={"20px"}
                        borderBottom={"1px solid #808080"}
                        className="pointer"
                        onClick={() => this.navigatePosts(item.id)}
                        data-test-id="list-item"
                      >
                        {this.renderDescription(
                          item.id,
                          item.attributes.description
                        )}
                        <Box display="flex">
                          <Box
                            sx={{
                              width: "200px",
                              height: "133px",
                              marginRight: "16px"
                            }}
                            flexShrink={0}
                          >
                            {item.attributes.thumbnail ? (
                              <img
                                src={item.attributes.thumbnail}
                                alt="post"
                                width={"100%"}
                                height={"100%"}
                              />
                            ) : (
                              <img
                                src={NoThumbnail}
                                alt="post"
                                width={"100%"}
                                height={"100%"}
                              />
                            )}
                          </Box>
                          {item.attributes.url && (
                            <Box
                              flexGrow={1}
                              overflow={"hidden"}
                              className="ellipsis"
                            >
                              <a
                                href={item.attributes.url}
                                onClick={e => e.stopPropagation()}
                                target="_blank"
                                className={"linkColor"}
                              >
                                {item.attributes.url}
                              </a>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}
              {this.serchedItem === "events" && (
                <Box display={"flex"} flexDirection={"column"} gridGap={"20px"}>
                  {this.state.renderFilter.map(item => (
                    <EventContainer
                      container
                      key={item.id}
                      onClick={() => this.NavigateToCalendarDetails(item.id)}
                      data-test-id="event-item"
                    >
                      <Grid item className="GridCardImgContainer" xs={5}>
                        {item.attributes.cover_image && (
                          <img
                            className="CardImg"
                            src={item.attributes.cover_image}
                            alt={"event-img"}
                          />
                        )}
                        <div className="GradientBox" />
                      </Grid>
                      <Grid className="GridContentContainer" item xs={7}>
                        <Box display={"flex"} justifyContent={"space-between"}>
                          <Box maxWidth={"80%"}>
                            <Box
                              component={"h4"}
                              m={0}
                              sx={{ fontSize: { xs: "18px", sm: "24px" } }}
                              fontWeight={"bold"}
                              className="ellipsis"
                              fontFamily={"verdana"}
                            >
                              {item.attributes.event_name}
                            </Box>
                            <Box
                              component={"p"}
                              m={0}
                              mt={"3px"}
                              sx={{ fontSize: { xs: "14px", sm: "18px" } }}
                              className="ellipsis"
                              fontWeight={"normal"}
                              color="#808080"
                              fontFamily={"verdana"}
                            >
                              {item.attributes.full_name
                                ? item.attributes.full_name
                                : item.attributes.community_name}
                            </Box>
                          </Box>
                          <Box
                            component={"p"}
                            fontFamily={"Verdana"}
                            sx={{ fontSize: { xs: "10px", sm: "12px" } }}
                            color={"#005487"}
                            fontWeight={"bold"}
                            m={0}
                            textAlign={"center"}
                          >
                            {moment(
                              new Date(String(item.attributes.start_date))
                            ).format("DD")}
                            <br />
                            {moment(
                              new Date(String(item.attributes.start_date))
                            )
                              .format("MMMM")
                              .toUpperCase()
                              .slice(0, 3)}
                          </Box>
                        </Box>
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          {
                            <Box sx={{ display: { xs: "none", sm: "block" } }}>
                              {" "}
                              <AvatarGroup spacing={20} max={3}>
                                {item.attributes.interest_event_count &&
                                  item.attributes.interest_event_count[0].account_images.map(
                                    img => {
                                      return (
                                        <Avatar
                                          src={String(img.photo)}
                                          key={String(img.photo)}
                                        />
                                      );
                                    }
                                  )}
                              </AvatarGroup>
                            </Box>
                          }
                          <Button
                            className={
                              item.attributes.is_interested
                                ? "intrestedBtn"
                                : "noIntrestBtn"
                            }
                            onClick={e => this.intrestedBtn(e, item.id)}
                            data-test-id="intrested-btn"
                          >
                            Interested
                          </Button>
                        </Box>
                      </Grid>
                    </EventContainer>
                  ))}
                </Box>
              )}
            </Box>
          </InfiniteScroll>
        </Wrapper>
      </>
    );
  }
  // Customizable Area End
}


// Customizable Area Start
const Wrapper = styled(Box)({
  "& .scoller-infinite": {
    overflow: "hidden !important"
  },
  "& .title": {
    textTransform: "capitalize"
  },
  "& .photoContainer": {
    display: "grid",
    gridTemplateColumns:
      "repeat(auto-fill, 195px)" /* Fixed width for each item */,
    gridAutoRows: "195px" /* Fixed height for each item */,
    gap: "16px",
    justifyContent: "space-between"
  },
  "& .photoAuthor": {
    position: "absolute",
    bottom: "16px",
    left: "16px",
    right: "16px"
  },
  "& .ellipsis": {
    maxWidth: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  "& .photoImg": {
    height: "100%",
    width: "100%",
    borderRadius: "12px"
  },
  "& .playIcon": {
    top: "50%",
    width: "22px",
    left: "50%",
    height: "22px",
    transform: "translate(-50%,-50%)"
  },
  "& .videoContainer": {
    display: "flex",
    flexDirection: "column",
    gap: "20px"
  },
  "& .urlContainer": {
    display: "flex",
    gap: "20px",
    flexDirection: "column"
  },
  "& .linkColor": {
    color: "#005487",
    fontFamily: "Verdana",
    fontSize: "17px"
  },
  "& .pointer": {
    cursor: "pointer"
  }
});

const EventContainer = styled(Grid)({
  height: "140px",
  width: "auto",
  display: "flex",
  borderRadius: "15px",
  justifyContent: "flex-end",
  overflow: "hidden",
  marginInline: "2px",
  boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.25)",
  "& .GradientBox": {
    background:
      "linear-gradient(-90deg,#fff 0%,rgba(255, 255, 255, 0.337) 50%,rgba(255, 255, 255, 0) 100%)",
    position: "absolute",
    top: "0px",
    width: "100%",
    height: "100%"
  },
  "& .GridCardImgContainer": {
    height: "inherit",
    position: "relative",
    cursor: "pointer"
  },
  "& .CardImg": {
    width: "100%",
    height: "100%"
  },
  "& .GridContentContainer": {
    backgroundColor: "white",
    justifyContent: "space-between",
    height: "inherit",
    padding: "20px 16px 16px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column"
  },
  "& .MuiAvatarGroup-avatar": {
    borderWidth: "1px",
    width: "31px",
    fontSize: "14px",
    height: "31px"
  },
  "& .ellipsis": {
    maxWidth: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  "& .intrestedBtn": {
    borderRadius: "10px",
    color: "#005487",
    textTransform: "none",
    backgroundColor: "#f4f4f4",
    height: "40px",
    width: "168px",
    fontWeight: "bold"
  },
  "& .noIntrestBtn": {
    borderRadius: "10px",
    color: "#fff",
    textTransform: "none",
    backgroundColor: "#005487",
    height: "40px",
    fontWeight: "bold",
    width: "168px"
  }
});
// Customizable Area End
