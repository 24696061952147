export const edit = require("../assets/assets/edit.png");
export const PostUser = require("../assets/assets/user.png");
export const Camera = require("../assets/assets/camera.png");
export const Chat = require("../assets/assets/chat.png");
export const Like = require("../assets/assets/like.png");
export const LikeFill = require("../assets/assets/like_fill.png");
export const Link = require("../assets/assets/link.png");
export const LinkFill = require("../assets/assets/link_fill.png");
export const More = require("../assets/assets/more.png");
export const Notification = require("../assets/assets/notification.png");
export const Send = require("../assets/assets/send.png");
export const SendFill = require("../assets/assets/send_fill.png");
export const User = require("../assets/assets/user.png");
export const Home = require("../assets/assets/home.png");
export const CommentFill = require("../assets/assets/comment_fill.png");
export const eagel = require("../assets/assets/eagel.png");
export const youtubeIcon = require("../assets/assets/Youtube.png");
export const vimeoIcon = require("../assets/assets/Vimeo.png");
export const StarIcon = require("../assets/star.svg");
export const SendIcon = require("../assets/send.svg");
export const MoreIcon = require("../assets/more.svg");
export const DescIcon = require("../assets/desc.svg");
export const LinkIcon = require("../assets/link.svg");
export const CalenderIcon = require("../assets/calender.svg");
export const TimeIcon = require("../assets/time.svg");
export const BussinessIcon = require("../assets/bussiness.svg");
export const BackArrow = require("../assets/arrow-left.svg");
export const Play = require("../assets/play.png");
export const NoThumbnail = require("../assets/noThumbnail.png");
