Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.brandAPiEndPoint = "catalogue/brands";
exports.postApiend="POST";
exports.filterbrandAPiEndPoint = "filter_items/filtering?q[brand_id]=";
exports.singleCategoryAPiEndPoint = "filter_items/filtering?q[category_id]=";
exports.cataloguesAPiEndPoint = "filter_items/filtering?";
exports.filterList="bx_block_filter_items/filtering/add_filter_on_search?";
exports.intrestedEventEnd="bx_block_events/events/event_make_interested";
// Customizable Area End