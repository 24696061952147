export const AddIcon = require("./assets/add.svg");
export const AssessmentIconLayout = require("./assets/assessmentTest.svg");
export const global = require("./assets/global.svg");
export const gallery = require("./assets/gallery.svg");
export const close = require("./assets/close.svg");
export const RedNotification = require("./assets/redNotification.svg");
export const BlueNotification = require("./assets/blueNotification.svg");
export const notificationIcon = require("./assets/notification.svg");
export const people =require("./assets/people.svg");
export const CommunitiesIcon =require("./assets/communities.svg");
export const Medal =require("./assets/medal-star.svg");
export const Calender =require("./assets/calendar.svg");
export const Home =require("./assets/home-2.svg");
export const BlueHome =require("./assets/blue-home-2.svg");
export const BlueMedal =require("./assets/blue-medal-star.svg");
export const BlueCalender =require("./assets/blue-calendar.svg");
export const BlueAssessment =require("./assets/blue-assessment.svg");
export const Chat =require("./assets/message-text.svg");
export const BlueChat =require("./assets/blue-message-tex.svg");
export const SettingIcon =require("./assets/setting-2.svg");
export const Refresh =require("./assets/refresh-2.svg");
export const HelpIcon =require("./assets/Help.svg");
export const MyEvent =require("./assets/myevent.svg");
export const Broadcaste =require("./assets/broadcast.svg");
export const SaveIcon =require("./assets/save-blue.svg");
export const logOut =require("./assets/Sign Out.svg");
export const Filter = require('./assets/filter.svg');