// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
import { getStorageData } from "../../../framework/src/Utilities";
import React from "react";
import { Box } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  renderFilter: {
    id: string;
    type: string;
    attributes: {
      id: number;
      event_name?: string;
      timezone?: string;
      description?: string;
      start_date?: string;
      end_date?: string;
      creating_time?: string;
      start_time?: string;
      end_time?: string;
      cover_image?: string;
      is_interested?: boolean;
      interested_count?: number;
      created_at?: string;
      full_name?: string | null;
      community_name?: string;
      profile_picture?: string | null;
      community_profile?: string;
      community_account_id?: number;
      sharing_time?: string;
      interest_event_count?: {
        account_images: { photo: string | null }[];
        count: number | null;
      }[];
      name?: string | null;
      url?: string | null;
      account_id?: number | null;
      community_id?: number | null;
      post_type?: string;
      updated_at?: string;
      thumbnail?: string | null;
      images_and_videos: string[]; // This should be defined as needed
      is_liked?: boolean;
      comments_count?: number;
      video_title?: string | null;
      video_thumbnail?: string | null;
    };
  }[];
  loading: boolean;
  newPostListCount: number;
  pageNo: number;
  pageLoder: boolean;
  readMorendex: string | number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FilteritemsNewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  interestedEventApiCallId: string = "";
  listFilterId: string = "";
  serchedItem: string = "";
  serchedValue: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      renderFilter: [],
      loading: false,
      pageLoder: false,
      newPostListCount: 0,
      pageNo: 1,
      readMorendex: ""
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.interestedEventApiCallId) {
      this.setState({ loading: false });
      if (responseJson && responseJson.data) {
        toast.success(responseJson.data[0].message);
      }
    }
    if (apiRequestCallId === this.listFilterId) {
      this.setState({ loading: false, pageLoder: false });
      if (responseJson && responseJson.data) {
        this.setState({
          renderFilter: [...this.state.renderFilter, ...responseJson.data],
          newPostListCount: responseJson.data.length
        });
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.paramSeperation();
  }

  paramSeperation = () => {
    const param =
      this.props.navigation.getParam("navigationBarTitleText") || "";
    if (param.split("-")[1]) {
      this.serchedValue = param.split("-")[1];
    } else {
      this.serchedValue = "";
    }

    if (param.split("-")[0]) {
      this.serchedItem = param.split("-")[0];
    } else {
      this.serchedItem = "";
    }

    this.setState({ pageNo: 1, renderFilter: [] });
    this.filterItemFunction(1);
  };

  componentDidUpdate(prevProps: Readonly<Props>): void {
    // Check if the route has changed
    const prevParam =
      prevProps.navigation.getParam("navigationBarTitleText") || "";
    const param =
      this.props.navigation.getParam("navigationBarTitleText") || "";
    if (prevParam !== param) {
      this.paramSeperation();
    }
  }

  handleBack = () => {
    this.props.navigation.geBack();
  };

  returnVideoName = (type?: string | null) => {
    return type === "Vimeo" ? "VIMEO.COM" : "YOUTUBE.COM";
  };

  navigatePosts = (index: string | number) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "SinglePost");

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), index);
    this.send(message);
  };

  filterItemFunction = async (pageNo?: number) => {
    let page_limit = this.serchedItem === "photos" ? 16 : 10;

    let page_no =
      pageNo === 1
        ? 1
        : Math.ceil(this.state.renderFilter.length / page_limit) + 1;
    this.setState({ pageNo: page_no });
    pageNo === 1
      ? this.setState({ loading: true })
      : this.setState({ pageLoder: true });

    const signupToken = await getStorageData("SignupSuccessToken");
    const loginToken = await getStorageData("LoginSuccessToken");
    const header = {
      token: signupToken || loginToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.listFilterId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.filterList +
        `filter_type=${this.serchedItem}&search=${
          this.serchedValue
        }&page=${page_no}&per_page=${page_limit}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  NavigateToCalendarDetails = (id: string | number) => {
    this.props.navigation.navigate("CalendarEventDetails", { id: id });
  };

  renderHeader = (
    full_name?: string | null,
    community_name?: string | null
  ) => {
    return full_name || community_name;
  };

  sharingTime = (time?: string | null) => {
    return `posted this • ${time}`;
  };

  intrestedBtn = async (
    e: React.MouseEvent<HTMLButtonElement>,
    id: string | number
  ) => {
    e.preventDefault();
    e.stopPropagation();
    const intrest = this.state.renderFilter.find(item => item.id === id);
    if (intrest) {
      let signupToken = await getStorageData("SignupSuccessToken");
      let loginToken = await getStorageData("LoginSuccessToken");
      const header = {
        token: signupToken || loginToken
      };
      let formdata = new FormData();
      formdata.append("event_id", intrest.id);
      formdata.append(
        "is_interested",
        JSON.stringify(!intrest.attributes.is_interested)
      );
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.interestedEventApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.intrestedEventEnd
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiend
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      const indexToUpdate = this.state.renderFilter.findIndex(
        item => item.id === id
      );
      const newRenderFilter = [...this.state.renderFilter];
      newRenderFilter[indexToUpdate].attributes.is_interested = !intrest
        .attributes.is_interested;
      this.setState({ renderFilter: newRenderFilter, loading: true });
    }
  };

  readMoreFn = (e: React.MouseEvent<HTMLElement>, id: string | number) => {
    e.stopPropagation();
    if (id) {
      this.setState({ readMorendex: id });
    }
  };

  renderDescription = (id: string, description?: string) => {
    const { readMorendex } = this.state;

    if (!description) {
      return null;
    }

    const truncatedDescription = description.slice(0, 50);
    const shouldDisplayReadMore =
      description.length > 50 && readMorendex !== id;

    const isReadMoreActive = readMorendex === id;
    const trunckDes = `${truncatedDescription}${
      shouldDisplayReadMore ? "..." : ""
    }`;
    const displayText = isReadMoreActive ? description : trunckDes;
    return (
      <Box
        component="p"
        m={0}
        mb={"20px"}
        color="#808080"
        fontSize="20px"
        fontFamily="Verdana"
      >
        {displayText}
        {shouldDisplayReadMore && (
          <Box
            component="span"
            data-testid="read-more"
            fontSize={"17px"}
            onClick={(e: React.MouseEvent<HTMLElement>) =>
              this.readMoreFn(e, id)
            }
          >
            {" "}
            show more
          </Box>
        )}
      </Box>
    );
  };
  // Customizable Area End
}
